<template>
  <Heading noDecoration text-align="center">Talk information</Heading>
  <div class="accommodation">
    <div>
      <b class="mb-4">Talk title</b>
      <p class="mb-4">{{ talk.title }}</p>
      <b class="mb-4">Track</b>
      <p class="mb-4">{{ makeFieldName(track) }}</p>
      <b class="mb-4">Talk description</b>
      <p class="mb-4">{{ talk.description }}</p>
      <!--    <b class="mb-4">Link to the video</b>
                                    <br />
                                    <Link
                                      link="https://arctic-con.com/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="mb-4"
                                    >
                                      https://app.clickup.com/t/xzdmbf
                                    </Link>-->
    </div>
  </div>
</template>

<script>
import { makeFieldName } from "@/assets/js/utils";
import Heading from "@/components/common/Heading";

export default {
  name: "TalkInfo",
  props: {
    talk: {
      type: Object,
      default: () => ({}),
    },
    track: {
      type: String,
      default: "",
    },
  },
  components: { Heading },
  data: () => ({}),
  computed: {},
  methods: {
    makeFieldName,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.accommodation {
  display: flex;
  margin-top: 47px;
  column-gap: 32px;
  flex-wrap: wrap;

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  @media (min-width: $media-sm) {
    flex-wrap: nowrap;
    margin-top: 33px;
  }

  @media (min-width: $media-lg) {
    margin-top: 87px;
  }
}
</style>
