<template>
  <Heading noDecoration text-align="center">General information</Heading>
  <div v-for="[topic, list] in Object.entries(instructions)" :key="topic">
    <Heading noDecoration type="h4" class="subheader">
      {{ topic }}
    </Heading>
    <ul style="max-width: 951px">
      <li
        v-for="(item, index) in list"
        :key="item.slice(0, 5) + index"
        class="timing__list-item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  name: "Lounge",
  props: {
    instructions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Heading },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.heading--h4 {
  text-transform: uppercase;
}
</style>
