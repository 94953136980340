<template>
  <Heading noDecoration text-align="center">Bio information</Heading>
  <div class="bio" :class="{ 'keynote-line': keyNote }">
    <div class="w-100">
      <div class="bio-subtitle">Personal Information</div>
      <p><span>Name: </span>{{ bio.first_name }} {{ bio.last_name }}</p>
      <p><span>Company: </span>{{ bio.organization }}</p>
      <p><span>Position: </span>{{ bio.job_title }}</p>
      <span v-show="bio.social_media && bio.social_media.length">
        Connect:
      </span>
      <SocialMedia :social="bio.social_media" class="mt-3" />
    </div>
    <div
      v-show="bio.image"
      class="bio-img"
      :style="`background-image: url(${bio.image})`"
    >
      <div v-show="keyNote" class="badge">Keynote Speaker</div>
    </div>
  </div>
  <div v-show="bio.bio && bio.bio.length" class="bio-subtitle">Bio</div>
  <div v-html="bio.bio" />
</template>

<script>
import SocialMedia from "@/components/common/SocialMedia";
import Heading from "@/components/common/Heading";

export default {
  name: "Bio",
  props: {
    bio: {
      type: Object,
      default: () => ({}),
    },
    keyNote: {
      type: Boolean,
      default: false,
    },
  },
  components: { Heading, SocialMedia },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.bio {
  margin-top: 20%;
  display: flex;
  flex-wrap: wrap;

  span {
    color: $white;
  }

  &-subtitle {
    margin: 16px 0;
    color: $white;
    font-weight: $sansBold;
  }

  &-img {
    order: -1;
    width: 100%;
    height: 180px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .badge {
      background-color: #daecff;
      z-index: -1;
      color: #0b0a14;
      font-size: 16px;
      font-weight: 600;
      padding: 0 20px;
      position: absolute;
      top: -20%;
      left: 0;
      height: 180px;
      width: 100%;
      text-align: end;
    }
  }

  @media (min-width: $media-sm) {
    margin-top: 7%;
    flex-wrap: nowrap;
    &-img {
      order: 2;
    }
  }

  @media (min-width: $media-md) {
    &-img {
      height: 300px;

      .badge {
        font-size: 20px;
        top: 5%;
        left: 5%;
        height: 330px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}

.keynote-line {
  position: relative;

  @media (min-width: $media-md) {
    &:before {
      content: "";
      width: 3px;
      height: 100%;
      background: $red;
      color: $white;
      position: absolute;
      top: 0;
      left: -3%;
    }
  }
}
</style>
