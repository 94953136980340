<template>
  <div class="kit__tabs__blur">
    <nav class="padding-x">
      <router-link to="/">
        <img :src="logoPath" alt="Exploit logo" class="nav__logo" />
      </router-link>
    </nav>
  </div>
  <div class="kit">
    <Preloader v-if="isKitLoading" :loading="isKitLoading" class="mt-15" />
    <div class="kit__tabs">
      <div
        @click.prevent="setActiveTab('bio')"
        :class="{ active: isTabActive('bio') }"
      >
        Bio
      </div>

      <div
        @click.prevent="setActiveTab('talk')"
        :class="{ active: isTabActive('talk') }"
      >
        Talk
      </div>
      <div
        @click.prevent="setActiveTab('codes')"
        :class="{ active: isTabActive('codes') }"
      >
        Codes
      </div>
      <div
        @click.prevent="setActiveTab('lounge')"
        :class="{ active: isTabActive('lounge') }"
      >
        General information
      </div>
      <div
        @click.prevent="setActiveTab('accommodation')"
        :class="{ active: isTabActive('accommodation') }"
      >
        Accommodation
      </div>
      <div
        @click.prevent="setActiveTab('profile')"
        :class="{ active: isTabActive('profile') }"
      >
        Update Profile
      </div>
    </div>
    <div v-if="kit" class="kit__content padding-x">
      <div v-show="isTabActive('bio')">
        <Bio :bio="kit.bio" :keyNote="kit.is_keynote" />
      </div>
      <div v-show="isTabActive('codes')" class="kit__codes">
        <Error v-if="codesError">
          <template #heading>
            Sorry, something went wrong when we tried fetching your codes
          </template>
          <template #body>
            Please try again or contact us at
            <a :href="`mailto:${config.helpEmail}`" class="error__link">
              {{ config.helpEmail }}
            </a>
          </template>
        </Error>
        <div v-else>
          <Heading noDecoration class="kit__codes-title" text-align="center">
            Codes</Heading
          >
          <div class="kit__codes-block">
            <SpeakerCodesFAQ class="pr-5" />
            <SpeakerCodes :tickets="codes" :loading="isOrderLoading" />
          </div>
        </div>
      </div>
      <div v-show="isTabActive('talk')">
        <TalkInfo :talk="kit.talk" :track="kit.track" />
      </div>
      <div v-show="isTabActive('lounge')">
        <Lounge
          :instructions="info.speaker_instructions"
          :loading="isInfoLoading"
        />
      </div>
      <div v-show="isTabActive('accommodation')">
        <AccommodationInfo
          :accommodations="info.accommodation"
          :loading="isInfoLoading"
        />
      </div>
      <div v-show="isTabActive('profile')">
        <UpdateProfile
          :sponsor="kit"
          :sponsorLoading="isKitLoading"
          :is-sponsor="false"
        />
      </div>
    </div>
    <Error v-if="kitError" class="error">
      <template #heading> Something went wrong</template>
      <template #body>
        Please try again or contact us at
        <a :href="`mailto:${config.helpEmail}`" class="error__link">
          {{ config.helpEmail }}
        </a>
      </template>
    </Error>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
// import Button from "@/components/common/Button";
import Error from "@/components/common/Error";
import Bio from "./Bio";
import TalkInfo from "./TalkInfo";
import Lounge from "./Lounge";
// import Technique from "./Technique";
import SpeakerCodesFAQ from "./SpeakerCodesFAQ";
import SpeakerCodes from "./SpeakerCodes";
import AccommodationInfo from "../exhibitorKit/AccommodationInfo";
import config from "../../../public/config.json";
import UpdateProfile from "../exhibitorKit/UpdateProfile";

export default {
  name: "SpeakerKit",
  props: {},
  components: {
    Preloader,
    UpdateProfile,
    Heading,
    // Button,
    // ContactUs,
    SpeakerCodes,
    SpeakerCodesFAQ,
    Bio,
    AccommodationInfo,
    Error,
    // Technique,
    TalkInfo,
    Lounge,
  },
  data: () => ({
    config,
    activeTab: "bio",
    isKitLoading: false,
    isOrderLoading: false,
    isInfoLoading: false,
    kit: null,
    kitError: null,
    info: null,
    infoError: null,
    codes: null,
    codesError: null,
  }),
  computed: {
    logoPath() {
      return require("@/assets/img/logo.png");
    },
  },
  mounted() {
    if (history.state.forward) {
      this.activeTab = "bio"; //for redirect with browser "back" arrow
    } else if (
      history.state.back &&
      (history.state.back.includes("invite") ||
        history.state.back.includes("register-multiple"))
    ) {
      this.activeTab = "codes";
    } else {
      this.activeTab = "bio";
    }

    this.isKitLoading = true;
    this.isOrderLoading = true;
    this.isInfoLoading = true;
    this.getKitInfo();
    this.getOrder();
    this.getInstructionsInfo();
  },
  methods: {
    ...mapActions(["GET"]),
    getKitInfo() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/speaker_kit/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.kit = resp.data;
          this.isKitLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.kitError = true;
          this.isKitLoading = false;
        });
    },
    getInstructionsInfo() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/exhibitor_kit`,
      })
        .then((resp) => {
          console.log(resp.data, "asdasd");
          // console.log("get kit", resp.data);
          this.info = resp.data;
          this.isInfoLoading = false;
        })
        .catch((err) => {
          console.log(err, "qweqw");
          this.infoError = true;
          this.isInfoLoading = false;
        });
    },
    getOrder() {
      // for testing:
      // 8cdd467a584b46abdb53718e5d7ef3224039204a58699897ee701eec4b6ce98e98ef7d0d362080b29996d4409bf78135afca267e1b4aacbd3fd11e8781b04c3a
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/order/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.codes = resp.data;
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codesError = true;
          this.isOrderLoading = false;
        });
    },
    isTabActive(menuItem) {
      return this.activeTab === menuItem;
    },
    setActiveTab(menuItem) {
      this.activeTab = menuItem;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

nav {
  padding-top: 16px;
  padding-bottom: 16px;

  .nav__logo {
    width: 137px;
  }

  @media (min-width: $media-sm) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media (min-width: $media-lg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.kit {
  padding-top: 70px;

  &__tabs {
    position: fixed;
    top: 72px;
    width: 100%;
    z-index: 5;
    background-color: $black;
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__blur {
      width: 100vw;
      height: 72px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
      z-index: +1;

      @media (min-width: $media-md) {
        height: 110px;
      }
    }

    div {
      cursor: pointer;
      padding: 10px;
      font-size: 14px;
      color: $dark-gray;
      text-transform: uppercase;
      border-top: 5px solid $black;
      border-bottom: 5px solid $black;

      font-family: Oswald;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.05em;
      color: $white;
    }
  }

  &__tabs .active {
    color: #ed1c24;
    border-top: 5px solid #ed1c24;
    border-bottom: 5px solid #ed1c24;
  }

  &__content {
    padding-top: 135px;
    padding-bottom: 35px;
  }

  &__codes {
    &-title {
      margin-bottom: 28px;
      font-family: $Oswald;
      font-weight: 600;
      font-size: 72px;
      line-height: 107px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (min-width: $media-xs) {
    &__content {
      padding-top: 85px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 122px;
    &__tabs {
      top: 109px;
    }
    &__codes {
      &-title {
        margin-bottom: 56px;
      }

      &-block {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media (min-width: $media-md) {
    &__tabs {
      div {
        font-size: 24px;
        padding: 8px 35px;
      }
    }

    @media (min-width: $media-lg) {
      &__tabs {
        div {
          padding: 8px 50px;
        }
      }

      &__content {
        padding-top: 129px;
        padding-bottom: 136px;
      }

      &__codes {
        &-title {
          margin-bottom: 87px;
        }
      }
    }
  }
}
</style>
